import axios from 'axios'
import auth from './auth'
import config from '../config/config'

const createApiUrl = (endpoint) => `${config.BACKEND_HOST}${endpoint}`
const createDesktopApiUrl = (endpoint) => `${config.BACKEND_HOST_EXT}${endpoint}`

const getParamsOrBody = ({ params, method = 'GET' }) => {
  const paramOrBody = (method.toUpperCase() === 'GET') ? 'params' : 'body'
  const options = { [paramOrBody]: params }

  return options
}

async function requestApi(options) {
  const {
    url,
    params,
    body,
    app,
    ...restOptions
  } = options


  let headers = {
    ...options.headers,
    'Content-Type': 'application/json',
  }

  if (auth.getTeamUUID() !== undefined) {
    headers['x-team-uuid'] = auth.getTeamUUID()
  }

  if (auth.getToken() !== undefined) {
    headers['Authorization'] = `Bearer ${auth.getToken()}`
  }

  try {
    const paramsOrBody = getParamsOrBody(options)

    const data = await request({
      ...restOptions,
      url: app === 'desktop' ? createDesktopApiUrl(url) : createApiUrl(url),
      headers,
      ...paramsOrBody
    })

    return ({ error: null, data })
  } catch (error) {
    return ({ error, data: null })
  }
}

const request = function(options = {}) {
  const config = {
    method: 'GET',
    headers: {},
    ...options,
  }

  return axios.request(config)
    .then(({ data, status, headers, statusText, config }) => {
      return({
        response: data,
        headers: headers,
      })
    })
    .catch((error) => {
      if (error.response && error.response.status === 401) {
        auth.clearAll()
        window.history.pushState({}, '', '/')
        window.location.reload()
      }
      return Promise.reject(error)
    })
}

export default requestApi
