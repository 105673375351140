import Cookies from 'js-cookie';
const TOKEN_KEY = 'frontkick_token';
const TEAM_UUID = 'frontkick_team_uuid';
const PROVIDER_KEY = 'frontkick_provider';

const auth = {
  getToken() {
    return Cookies.get(TOKEN_KEY, { path: '/' })
  },

  getProvider() {
    return Cookies.get(PROVIDER_KEY, { path: '/' })
  },

  clearAll() {
    Cookies.remove(TOKEN_KEY, { path: '/' })
    Cookies.remove(TEAM_UUID, { path: '/' })
    Cookies.remove(PROVIDER_KEY, { path: '/' })
    // window.localStorage.clear()
    return true
  },

  setToken(value) {
    Cookies.set(TOKEN_KEY, value, { path: '/', expires: 30 })
  },

  setProvider(value) {
    Cookies.set(PROVIDER_KEY, value, { path: '/', expires: 30 })
  },

  // uuid
  saveTeamUUID(value) {
    Cookies.set(TEAM_UUID, value, { path: '/', expires: 365 })
  },

  getTeamUUID() {
    return Cookies.get(TEAM_UUID, { path: '/' })
  }
};

export default auth;
