import React, {Component} from "react";
import {Icon, Intent, Spinner, Toaster} from "@blueprintjs/core";
import Mic from '@material-ui/icons/Mic'
import MicOff from '@material-ui/icons/MicOff'
import styles from "./participant.module.scss";

class Participant extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullscreen: false
    }

    this.streamRef = React.createRef()
    this.containerRef = React.createRef()
  }

  componentDidMount() {
    this.initParticipant()
    document.addEventListener('fullscreenchange', this.onExitFullscreen);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.participant && this.props.participant) {
      this.initParticipant()
    }
  }

  componentWillUnmount() {
    document.removeEventListener('fullscreenchange', this.onExitFullscreen);
  }

  onExitFullscreen = () => {
    if (!document.fullscreenElement) {
      this.setState({
        fullscreen: false
      })
    }
  }

  initParticipant = () => {
    const {participant} = this.props

    if (participant && participant.tracks) {
      const tracks = Array.from(participant.tracks.values()).filter(t => t.isEnabled)
      this._attachTracks(tracks)
    }
  }

  removeTrack = (track) => {
    this._detachTracks([track])
  }

  addTrack = (track) => {
    this._attachTracks([track])
  }

  _detachTracks = (tracks) => {
    tracks.forEach((track) => {
      track.detach().forEach((detachedElement) => detachedElement.remove())
    })
  }

  _attachTracks = (tracks) => {
    tracks.forEach((track) => {
      this.streamRef.current.appendChild(track.attach())
    })
  }

  exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
        .then(() => {
          this.setState({
            fullscreen: false
          })
        })
    }
  }

  onFullscreen = () => {
    if (document.fullscreenElement) {
      this.exitFullscreen()
    } else {
      const {participant} = this.props;
      const videoTrack = [...participant.tracks.values()].find(track => {
        return track.kind === 'video' && track.isEnabled
      })

      if (videoTrack) {
        this.containerRef.current.requestFullscreen()
          .then(() => {
            this.setState({
              fullscreen: true
            })
          })
          .catch(() => {
            Toaster.create({
              button: "Ok",
              icon: "warning-sign",
              intent: Intent.WARNING,
              message: "Fullscreen is not available",
            })
          })
      }
    }
  }

  renderPlaceholder = () => {
    const {image} = this.props;

    return image
      ? <img className={styles.placeholder} src={image} alt="placeholder"/>
      : <div className={styles.placeholderEmpty}>
        <Icon icon={'person'} iconSize={72}/>
      </div>
  }

  renderParticipantView = (hasVideo) => {
    const {mirror} = this.props
    const streamStyles = [styles.stream]
    if (!hasVideo) streamStyles.push(styles.streamHidden)
    if (mirror) streamStyles.push(styles.streamMirror)

    return (
      <>
        <div className={streamStyles.join(' ')} ref={this.streamRef}/>
        {
          !hasVideo && this.renderPlaceholder()
        }
      </>
    )
  }

  renderFooter = (hasAudio) => {
    const {name, info, isMe, isPreview} = this.props

    if (isPreview) return null

    return (
      <div className={styles.footer}>
        <div className={styles.name}>{name} {isMe ? '(You)' : ''}</div>
        <div className={styles.info}>{info}</div>
        <div className={styles.controls}>
          <div className={styles.icon}>
            {
              hasAudio
                ? <Mic/>
                : <MicOff/>
            }
          </div>
        </div>
      </div>
    )
  }

  renderHeader = (hasVideo) => {
    const {isMe, isPreview} = this.props
    const {fullscreen} = this.state

    if (isPreview || isMe) return null

    return (
      <div className={styles.header}>
        {
          (hasVideo || fullscreen) &&
          <button type="button" onClick={this.onFullscreen} className="bp3-button bp3-minimal">
            <Icon icon={'fullscreen'} iconSize={16} color={'#fff'}/>
          </button>
        }
      </div>
    )
  }

  render() {
    const {participant, localControls} = this.props
    const {fullscreen} = this.state
    let hasAudio = false, hasVideo = false

    if (participant && participant.tracks) {
      participant.tracks.forEach(track => {
        if (!hasAudio && track.kind === 'audio' && track.isEnabled) hasAudio = true
        if (!hasVideo && track.kind === 'video' && track.isEnabled) hasVideo = true
      })
    }

    return (
      <div ref={this.containerRef} className={styles.container} onDoubleClick={this.exitFullscreen}>
        <div className={styles.streamContainer}>
          {
            participant
              ? this.renderParticipantView(hasVideo)
              : <Spinner intent={Intent.PRIMARY} size={100}/>
          }
          {this.renderFooter(hasAudio)}
          {this.renderHeader(hasVideo)}
        </div>
        {fullscreen && <div className={styles.localControls}>{localControls}</div>}
      </div>
    )
  }
}

export default Participant
