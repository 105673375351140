export const ACTION = "ACTION";
export const LOGOUT = "LOGOUT";
export const LOGIN = "LOGIN";
export const LOAD_PROFILE = "LOAD_PROFILE";
export const INVITE_MEMBER = "INVITE_MEMBER";
export const ERROR = "ERROR";
export const LOAD_APPS = "LOAD_APPS";
export const LOAD_APPLICATIONS = "LOAD_APPLICATIONS";
export const CREATE_APPLICATION = "CREATE_APPLICATION";
export const GET_ROLES = "GET_ROLES";
export const CREATE_ROLE = "CREATE_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const DELETE_ROLE = "DELETE_ROLE";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";
export const INSTALLED_APPLICATIONS = "INSTALLED_APPLICATIONS";
export const SELECT_TEAM = "SELECT_TEAM";
export const GET_TEAM_INVITE = "GET_TEAM_INVITE";
export const ACCEPT_INVITE = "ACCEPT_INVITE";
export const LOAD_MEMBERS = "LOAD_MEMBERS";
export const DELETE_MEMBER = "DELETE_MEMBER";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const GET_TEAM = "GET_TEAM";
export const GET_ME = "GET_ME";
export const GET_MEMBER_WORKSPACES = "GET_MEMBER_WORKSPACES";
export const CREATE_WORKSPACE = "CREATE_WORKSPACE";
export const DELETE_WORKSPACE = "DELETE_WORKSPACE";
export const ADD_TOAST = "ADD_TOAST";
export const GET_WORKSPACES = "GET_WORKSPACES";
export const UPDATE_WORKSPACE = "UPDATE_WORKSPACE";
export const GET_INVITE = "GET_INVITE";
export const CREATE_TEAM = "CREATE_TEAM";
export const GET_ACTIVITY = "GET_ACTIVITY";
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const JOIN_ROOM = "JOIN_ROOM";
export const SAVE_ROOM = "SAVE_ROOM";
export const REFRESH_ROOM_PARTICIPANTS = "REFRESH_ROOM_PARTICIPANTS";
export const UPDATE_SITE_CATEGORY = "UPDATE_SITE_CATEGORY";
export const SEND_CODE = "SEND_CODE";
export const CONFIRM_CODE = "CONFIRM_CODE";
export const CREATE_RESOURCE = "CREATE_RESOURCE";
export const GET_RESOURCES = "GET_RESOURCES";
export const UPDATE_RESOURCE = "UPDATE_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const CREATE_SITE = "CREATE_SITE";
export const CHOOSE_TEAM = "CHOOSE_TEAM";
export const UPDATE_ACTIVITY = "UPDATE_ACTIVITY";
export const GET_MEMBER_APPLICATIONS = "GET_MEMBER_APPLICATIONS";
export const GET_KEY_SET = "GET_KEY_SET";
export const SET_KEY_SET = "SET_KEY_SET";
export const CREATE_KEY_SET = "CREATE_KEY_SET";
export const CREATE_CREDENTIAL = "CREATE_CREDENTIAL";
export const CREATE_CREDENTIALS = "CREATE_CREDENTIALS";
export const GET_CREDENTIALS = "GET_CREDENTIALS";
export const SHARE_CREDENTIALS = "SHARE_CREDENTIALS";
export const PRESENCE_SUBSCRIBE = "PRESENCE_SUBSCRIBE";
export const GET_TEAM_EVENTS = "GET_TEAM_EVENTS";