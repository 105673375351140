import React from "react";
import Mic from '@material-ui/icons/Mic'
import MicOff from '@material-ui/icons/MicOff'
import Videocam from '@material-ui/icons/Videocam'
import VideocamOff from '@material-ui/icons/VideocamOff'
import ScreenShare from '@material-ui/icons/ScreenShare'
import CallEnd from '@material-ui/icons/CallEnd'
import styles from "./roomControls.module.scss";

const RoomControls =
  ({
     joined,
     onToggleAudio,
     onToggleVideo,
     onDisconnect,
     onShareScreen,
     hasAudio,
     hasVideo,
     hasScreenSharing
   }) => {
    const buttonClasses = ["bp3-button bp3-minimal", styles.iconButton]
    const shareClasses = [...buttonClasses]

    if (hasScreenSharing) shareClasses.push(styles.filled)

    return (
      <div className={styles.container}>
        <button onClick={onToggleAudio} type="button" className={buttonClasses.join(' ')}>
          {hasAudio ? <Mic/> : <MicOff/>}
        </button>
        <button onClick={onToggleVideo} type="button" className={buttonClasses.join(' ')}>
          {hasVideo ? <Videocam/> : <VideocamOff/>}
        </button>
        {
          joined &&
          <button onClick={onShareScreen} type="button" className={shareClasses.join(' ')}>
            <ScreenShare/>
          </button>
        }
        {
          joined &&
          <button onClick={onDisconnect} type="button"
                  className={"bp3-button bp3-minimal " + styles.iconButton + ' ' + styles.danger}>
            <CallEnd/>
          </button>
        }
      </div>
    )
  };

export default RoomControls