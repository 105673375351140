import React from 'react';
import ParticipantListItem from "../ParticipantListItem";
import styles from "./participantList.module.scss";

const getStatus = (participant, roomParticipants) => {
  if (roomParticipants) {
    return Array.from(roomParticipants).some(([_, roomP]) => participant.id === roomP.identity)
  }

  return false
}

const ParticipantList = ({participants, localParticipant, roomParticipants, showStatus}) => {
  let allRoomParticipants = new Map()
  if (showStatus) {
    if (roomParticipants) allRoomParticipants = new Map([...roomParticipants])
    if (localParticipant) allRoomParticipants.set(localParticipant.id, localParticipant)
  }

  if (!Object.keys(participants).length) return null

  return (<div className={styles.participants}>
      <h4 className={"bp3-heading"}>Participants</h4>
      <div className={styles.participantsList}>
        {
          Object.entries(participants)
            .map(([id, participant]) => {
              const online = getStatus(participant, allRoomParticipants)

              return <ParticipantListItem
                key={id}
                image={participant.avatar}
                name={participant.name || participant.email}
                online={online}
                showStatus={showStatus}
              />
            })
        }
      </div>
    </div>
  )
};

export default ParticipantList;