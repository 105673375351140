import React, { Component } from "react";
import styles from './styles.module.scss'

export default class NotFound extends Component {
  render() {
    return (
      <div className={ styles.container }>
        <div className={ styles.emo }><span role="img" aria-label="Shrugging">🤷‍♀️</span></div>
      </div>
    );
  }
}