import React, { Component } from 'react'
import styles from './content.module.scss'

export default class Content extends Component {
  render() {
    const { title, children, renderTitle, actions, subtitle } = this.props

    return (
      <div className={ styles.container }>
        <div className={ styles.header }>
          <div className={ styles.top }>
            { renderTitle ? renderTitle() : <h1 className={ "bp3-heading" }>{ title }</h1> }
            <div className={ styles.actions }>
              { actions }
            </div>
          </div>
          { subtitle ? <h2 className={ "bp3-heading" }>{ subtitle }</h2> : null }
        </div>
        <div className={ styles.content }>
          { children }
        </div>
      </div>
    )
  }
}
