import React from 'react'
import { Button as BlueprintButton } from '@blueprintjs/core'

import styles from './button.module.scss';

export default function Button({mobileText, text, ...props}) {
  return(
    <>
      <div className={ styles.desktop }>
        <BlueprintButton {...props} text={ text }/>
      </div>
      <div className={ styles.mobile }>
        <BlueprintButton {...props} text={ mobileText || text }/>
      </div>
    </>
  )
}