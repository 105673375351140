import React, {Component} from 'react';
import {connect} from 'react-redux';
import TwilioVideo from 'twilio-video'
import {Alert, Intent} from "@blueprintjs/core";

import Button from '../../shared/Button'
import {joinRoom, saveRoom, refreshRoomParticipants} from "../../redux/actions";
import Participant from "./components/Participant";
import RoomControls from "./components/RoomControls";
import ParticipantList from "./components/ParticipantList";
import styles from './room.module.scss';

const BANDWIDTH_SETTINGS = {
  video: {
    mode: 'collaboration',
    maxSubscriptionBitrate: 8000000,
    dominantSpeakerPriority: 'high',
    maxTracks: 5,
    renderDimensions: {
      high: {width: 1920, height: 1080},
      standard: {width: 640, height: 480},
      low: {width: 320, height: 240}
    }
  }
}

const SHARESCREEN_CONFIG = {
  audio: false,
  video: true
}

class Room extends Component {
  constructor(props) {
    super(props);

    this.state = {
      room: null,
      preview: null,
      message: '',
      error: '',
      screenTrack: null,
      disconnectDialog: false
    }

    this.participantsRefs = {}
    this.localParticipantRef = React.createRef()
  }


  componentDidMount() {
    this.setupPreview()
  }

  setupPreview = () => {
    const localTracksPromise = TwilioVideo.createLocalTracks();

    localTracksPromise.then((tracks) => {
      const fakeLocalParticipant = {
        tracks
      };

      this.setState({
        preview: fakeLocalParticipant
      })
    }, (error) => {
      this.setState({error: 'Unable to access video: ' + error.message})

      // try to use only audio
      TwilioVideo.createLocalTracks({audio: true})
        .then(tracks => {
          this.setState({
            preview: { tracks }
          })
        })
        .catch(error => {
          this.setState({
            error: 'Unable to access audio: ' + error.message,
            preview: {}
          })
        })
    });
  }

  refreshParticipants = () => {
    const {roomConnectionData: {roomId, inviteId}, joinRoom, refreshRoomParticipants} = this.props

    return joinRoom({roomId, inviteId})
      .then(res => refreshRoomParticipants(roomId, res.response.record.participants))
      .catch(err => this.setState({error: err.message}))
  }

  join = () => {
    const {roomConnectionData: {twilioToken, roomId}} = this.props
    const {preview} = this.state
    this.setState({joinLoading: true})

    this.refreshParticipants()
      .then(() => {
        TwilioVideo.connect(twilioToken, {
          name: roomId,
          tracks: preview.tracks,
          bandwidthProfile: BANDWIDTH_SETTINGS
        })
          .then((room) => {
            this.setState({room, joinLoading: false}, this.setupEvents)
          })
          .catch((err) => {
            this.setState({error: err.message, joinLoading: false})
          })
      })
  }

  setupEvents = () => {
    const {room, preview} = this.state

    room.on('disconnected', () => {
      if (preview) preview.tracks.forEach((track) => track.stop())
      room.localParticipant.tracks.forEach((track) => track.detach())
      room.participants.forEach((participant) => {
        participant.tracks.forEach((track) => track.detach())
      })

      this.setState({
        room: null,
        preview: null,
        message: 'You have been disconnected from the room'
      })

      this.participantsRefs = {}
    })

    room.on('participantConnected', () => {
      this.refreshParticipants()
    })

    room.on('participantDisconnected', () => {
      this.forceUpdate()
    })

    room.on('trackSubscribed', (track, participant) => {
      console.log('trackAdded')
      if (participant.identity === room.localParticipant.identity) {
        this.localParticipantRef.addTrack(track)
        this.localParticipantRef.forceUpdate()
      }

      Object.entries(this.participantsRefs).forEach(([id, pRef]) => {
        if (id === participant.identity && pRef) {
          pRef.addTrack(track)
          pRef.forceUpdate()
        }
      })

      this.forceUpdate()
    })

    room.on('trackUnsubscribed', (track, participant) => {
      if (participant.identity === room.localParticipant.identity) {
        this.localParticipantRef.removeTrack(track)
        this.localParticipantRef.forceUpdate()
      }

      Object.entries(this.participantsRefs).forEach(([id, pRef]) => {
        if (id === participant.identity && pRef) {
          pRef.removeTrack(track)
          pRef.forceUpdate()
        }
      })

      this.forceUpdate()
    })
  }

  stopScreenShare = () => {
    const {room, screenTrack} = this.state

    room.localParticipant.videoTracks.forEach((track) => {
      if (track.name === screenTrack.id) {
        this.unpublishLocalTrack(track)
        track.stop()
      } else {
        track.enable()
      }
    })

    this.setState({screenTrack: null})
  }

  startScreenShare = () => {
    const {room} = this.state

    navigator.mediaDevices.getDisplayMedia(SHARESCREEN_CONFIG)
      .then(stream => {
        const screenTrack = stream.getVideoTracks()[0]
        screenTrack.addEventListener('ended', this.stopScreenShare)
        room.localParticipant.publishTrack(screenTrack)
        this.state.room.localParticipant.videoTracks.forEach((track) => {
          if (track.name === screenTrack.id) {
            this.localParticipantRef.current.addTrack(track)
          } else {
            track.disable()
          }
        })
        this.setState({
          screenTrack
        })
      })
  }

  onShareScreen = () => {
    const {screenTrack} = this.state

    if (screenTrack) {
      this.stopScreenShare()
    } else {
      this.startScreenShare()
    }
  }

  onDisconnect = () => {
    this.setState({
      disconnectDialog: true
    })
  }

  disconnect = () => {
    const {room} = this.state

    if (room) {
      this.setState({
        message: 'You have left the room'
      })
      room.disconnect()
    }
  }

  publishLocalTrack = async (track, noUpdate) => {
    const {room} = this.state

    track.enable && track.enable()
    if (room) {
      await room.localParticipant.publishTrack(track)
    }
    this.localParticipantRef.current.addTrack(track)
    !noUpdate && this.forceUpdate()
  }

  unpublishLocalTrack = (track, noUpdate) => {
    const {room} = this.state

    track.disable && track.disable()

    if (room) {
      room.localParticipant.unpublishTrack(track)
    }

    this.localParticipantRef.current.removeTrack(track)
    !noUpdate && this.forceUpdate()
  }

  createLocalTrack = (kind) => {
    const {preview} = this.state

    let createTrackFn;
    if (kind === 'audio') {
      createTrackFn = TwilioVideo.createLocalAudioTrack
    } else if (kind === 'video') {
      createTrackFn = TwilioVideo.createLocalVideoTrack
    } else {
      return
    }

    return new Promise(((resolve, reject) => {
      createTrackFn()
        .then(track => {
          this.setState({
            preview: {
              tracks: [...preview.tracks, track]
            }
          }, () => resolve(track))
        })
        .catch(reject)
    }))
  }

  deleteLocalTracks = (kind) => {
    return new Promise(((resolve) => {
      this.setState(state => {
        const newTracks = []

        state.preview.tracks.forEach(async t => {
          if (t.kind === kind) {
            await this.unpublishLocalTrack(t, true)
          } else {
            newTracks.push(t)
          }
        })

        return {
          preview: {
            tracks: newTracks
          }
        }
      }, () => resolve())
    }))
  }

  togglePreviewTrack = (kind) => {
    const {preview} = this.state
    const hasPreviewAudioTrack = preview.tracks.some(t => t.kind === kind)

    if (hasPreviewAudioTrack) {
      this.deleteLocalTracks(kind)
    } else {
      this.createLocalTrack(kind)
        .then(this.publishLocalTrack)
        .catch(() => this.setState({error: `Failed to enable ${kind}`}))
    }
  }

  toggleLocalTrack = (kind) => {
    const {room, preview, screenTrack} = this.state

    let trackType
    if (kind === 'audio') {
      trackType = 'audioTracks'
    }
    else if (kind === 'video') {
      trackType = 'videoTracks'
    }
    else {
      return
    }

    if (room.localParticipant[trackType].size) {
      room.localParticipant.tracks.forEach(t => {
        if (screenTrack && screenTrack.id === t.name) return

        if (t.kind === kind) {
          this.unpublishLocalTrack(t)
        }
      })
    } else {
      const hasPreviewAudioTrack = preview.tracks.some(t => t.kind === kind)

      if (hasPreviewAudioTrack) {
        preview.tracks.forEach(async track => {
          if (track.kind === kind) this.publishLocalTrack(track)
        })
      } else {
        this.createLocalTrack(kind)
          .then(this.publishLocalTrack)
          .catch(() => this.setState({error: `Failed to enable ${kind}`}))
      }
    }
  }

  onToggleTrack = (kind) => {
    const {room, preview} = this.state

    if (room) {
      this.toggleLocalTrack(kind)
    } else if (preview) {
      this.togglePreviewTrack(kind)
    }
  }

  onToggleAudio = () => this.onToggleTrack('audio')
  onToggleVideo = () => this.onToggleTrack('video')

  hasAvailableTrack = (kind) => {
    const {preview, room} = this.state
    if (!preview && !room) return false

    if (room) {
      return Array.from(room.localParticipant.tracks).some(([_, track]) => track.kind === kind && track.isEnabled)
    } else if (preview && preview.tracks) {
      return preview.tracks.some(track => track.kind === kind && track.isEnabled)
    }
  }

  hasAudio = () => this.hasAvailableTrack('audio')
  hasVideo = () => this.hasAvailableTrack('video')

  hasScreenSharing = () => {
    return !!this.state.screenTrack
  }

  renderLocalControls = () => {
    return <RoomControls
      joined={false}
      onToggleAudio={this.onToggleAudio}
      onToggleVideo={this.onToggleVideo}
      hasAudio={this.hasAudio()}
      hasVideo={this.hasVideo()}
    />
  }

  renderRoomMessage = (message) => {
    return <div className={styles.layout}>
      <div className={styles.fullscreenMessage}><h2 className={'bp3-heading'}>{message}</h2></div>
    </div>
  }

  render() {
    const {room, preview, message, error, screenTrack, joinLoading, disconnectDialog} = this.state
    const {roomConnectionData} = this.props
    let roomMessage = message

    if (!this.props.roomConnectionData) {
      roomMessage = 'The room is unavailable. Use invite link to join.'
    }

    if (roomMessage) {
      return this.renderRoomMessage(roomMessage)
    }

    const {twilioSid, participants} = roomConnectionData
    const roomParticipantsClasses = [styles.roomParticipants]
    const roomClasses = [styles.room]

    if (!!room) {
      roomParticipantsClasses.push(styles.roomParticipantsJoined)
      roomClasses.push(styles.roomJoined)
    }

    return (
      <div className={styles.layout}>
        <ParticipantList
          participants={participants}
          localParticipant={room && room.localParticipant}
          roomParticipants={room && room.participants}
          showStatus={!!room}
        />
        <div className={roomClasses.join(' ')}>
          <div className={roomParticipantsClasses.join(' ')}>
            {
              !room && <Participant
                ref={this.localParticipantRef}
                isMe
                isPreview
                participant={preview}
                localControls={this.renderLocalControls()}
                mirror={!screenTrack}
              />
            }
            {
              room && <Participant
                ref={this.localParticipantRef}
                key={room.localParticipant.identity}
                name={participants[room.localParticipant.identity].name}
                image={participants[room.localParticipant.identity].avatar}
                info={participants[room.localParticipant.identity].email}
                isMe
                participant={room.localParticipant}
                mirror={!screenTrack}
              />
            }
            {
              room && room.participants && Array.from(room.participants).map(([_, participant]) => {
                const id = participant.identity

                if (!participants.hasOwnProperty(id)) return null

                return <Participant
                  key={id}
                  ref={(ref) => this.participantsRefs[id] = ref}
                  name={participants[id].name}
                  image={participants[id].avatar}
                  info={participants[id].email}
                  participant={participant}
                  localControls={this.renderLocalControls()}
                />
              })
            }
          </div>
          <div className={styles.footer}>
            <RoomControls
              joined={!!room}
              onDisconnect={this.onDisconnect}
              onToggleAudio={this.onToggleAudio}
              onToggleVideo={this.onToggleVideo}
              onShareScreen={this.onShareScreen}
              hasAudio={this.hasAudio()}
              hasVideo={this.hasVideo()}
              hasScreenSharing={this.hasScreenSharing()}
            />
            {
              twilioSid && !room && <div className={styles.join}>
                <Button
                  disabled={!preview || joinLoading}
                  className={styles.joinButton}
                  onClick={this.join}
                  intent={Intent.PRIMARY}
                  fill text="Join Room"
                  mobileText="Join"
                  loading={joinLoading}
                />
              </div>
            }
          </div>
        </div>
        <Alert
          canEscapeKeyCancel={false}
          canOutsideClickCancel={false}
          confirmButtonText="Close"
          isOpen={error}
          onClose={() => this.setState({error: ''})}
          intent={Intent.WARNING}
        >
          <p>
            {error}
          </p>
        </Alert>
        <Alert
          isOpen={disconnectDialog}
          confirmButtonText="Leave"
          cancelButtonText="Cancel"
          onCancel={() => this.setState({disconnectDialog: false})}
          onConfirm={this.disconnect}
          intent={Intent.DANGER}
        >
          <h3 className={'bp3-heading'}>Are you sure you want to leave a call?</h3>
        </Alert>
      </div>
    )
  }
}

const mapStateToProps = ({rooms}, props) => {
  const {match: {params: {roomId}}} = props;

  return {
    roomConnectionData: rooms[roomId]
  }
}

export default connect(mapStateToProps, {joinRoom, saveRoom, refreshRoomParticipants})(Room);
