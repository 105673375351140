import React, { Component } from 'react'

import GoogleLogin from './GoogleLogin'
import MsalLogin from './MsalLogin'
import EmailLogin from './EmailLogin'

import config from '../config/config'
import auth   from '../utils/auth'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { login, loginExtension, sendCode, confirmCode, addToast } from '../redux/actions';

const APPLICATIONS_FOR_UPDATE = ['extension', 'ext-onboarding']

class LoginButton extends Component {
  render() {
    switch(this.props.provider) {
      case 'google': {
        return <GoogleLogin onClick={ this.onClick } {...this.props}/>
      }
      case 'msal': {
        return <MsalLogin onClick={ this.onClick } {...this.props}/>
      }
      case 'email': {
        return <EmailLogin
          sendCode={ this.props.sendCode }
          confirmCode={ this.props.confirmCode }
          codeLogin={ this.codeLogin }
          loginHint={ this.props.loginHint }/>
      }
      default:
        return
    }
  }

  codeLogin = ({token, response}) => {
    const { loader, onLogin, application } = this.props

    loader && loader(true)
    auth.setToken(token)
    auth.setProvider('email')

    return (new Promise((resolve) => {
      if (APPLICATIONS_FOR_UPDATE.includes(application) && window.chrome && window.chrome.runtime) {
        window.chrome.runtime.sendMessage(config.SIDEKICK_EXT_ID, {
          type: 'AUTH_TOKEN',
          payload: response,
          options: {
            closeOnDone: application === 'extension'
          }
        })
      }

      resolve()
    }))
    .then(() => onLogin(token) )
    .then(() => loader && loader(false))
    .catch(this.onError)
  }

  onClick = (token) => {
    const { loader } = this.props

    loader && loader(true)

    this.extensionLogin(token)
      .then(() => this.appLogin(token))
      .then(() => loader && loader(false))
      .catch(this.onError)
  }

  onError = (err) => {
    const { loader, addToast } = this.props

    console.log(err)
    if (err.message === "Network Error") {
      addToast({
        message: "Network Error. Please try again",
        intent: 'DANGER',
        timeout: 5000
      })
    }
    loader && loader(false)
  }

  appLogin = (token) => {
    const { provider, onLogin } = this.props

    return new Promise(((resolve, reject) => {
      this.props.login({token, provider})
        .then(({headers, error}) => {
          if (error) {
            reject(error)
          } else {
            auth.setToken(headers.authorization)
            auth.setProvider(provider)
            onLogin(headers.authorization)
            resolve()
          }
        })
        .catch(error => reject(error))
    }))
  }

  extensionLogin = async (token) => {
    const { provider, application } = this.props

    const { error, response } = await this.props.loginExtension({token, provider})
    if (error) {
      throw error
    }

    if (APPLICATIONS_FOR_UPDATE.includes(application) && window.chrome && window.chrome.runtime) {
      window.chrome.runtime.sendMessage(config.SIDEKICK_EXT_ID, {
        type: 'AUTH_TOKEN',
        payload: response,
        options: {
          closeOnDone: application === 'extension'
        }
      })
    }
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({login, loginExtension, sendCode, confirmCode, addToast}, dispatch)
};

export default connect(null, mapDispatchToProps)(LoginButton);
