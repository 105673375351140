import * as Sentry from '@sentry/browser'
import * as actions from "../actionTypes";

const initialState = {
  me: {},
  currentMember: null,
  invite: {},
  team_invites: [],
  teams: [],
  members: [],
  notifications: [],
  toast: {},
  rooms: {},
  siteCategories: {},
  team: {},
  activities: [],
  credentials: [],
  keySet: {},
  presenceChannel: null,
  team_events: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.LOGOUT: {
      return state
    }
    case actions.CHOOSE_TEAM: {
      return { ...state, team: action.team }
    }
    case actions.LOGIN:
    case actions.LOAD_PROFILE: {
      return { ...state, ...action.response.record }
    }
    case actions.GET_ME: {
      Sentry.addBreadcrumb({
        category: 'state',
        message: 'me ' + action.response.record,
        level: Sentry.Severity.Info
      });

      return { ...state, ...action.response.record } // { me, teams, invite, team_invites }
    }
    case actions.GET_TEAM: {
      const { members, activities } = action.response.record
      const me = state.me
      const currentMember = me.id ? members.find(({user}) => user.id && user.id.toString() === me.id.toString() ) : null

      Sentry.addBreadcrumb({
        category: 'state',
        message: 'currentMember ' + currentMember,
        level: Sentry.Severity.Info
      });

      return { ...state, members, activities, currentMember }
    }
    case actions.DELETE_MEMBER: {
      let idx = state.members.findIndex(member => member.uuid === action.response.record.uuid)

      const members = [
        ...state.members.slice(0, idx),
        ...state.members.slice(idx+1)
      ]

      return { ...state, members }
    }
    case actions.UPDATE_MEMBER: {
      let idx = state.members.findIndex(member => member.uuid === action.response.record.uuid)

      const newState = {}

      if (action.response.record.uuid === state.currentMember.uuid) {
        newState["currentMember"] = action.response.record
      }

      newState["members"] = [
        ...state.members.slice(0, idx),
        action.response.record,
        ...state.members.slice(idx+1)
      ]

      return { ...state, ...newState }
    }
    case actions.GET_NOTIFICATIONS: {
      return { ...state, notifications: action.response.collection }
    }
    case actions.CREATE_NOTIFICATION: {
      return { ...state, notifications: [action.response.record, ...state.notifications] }
    }
    case actions.ADD_TOAST: {
      return { ...state, toast: action.toast }
    }
    case actions.UPDATE_TEAM: {
      let idx = state.teams.findIndex(team => team.uuid === action.response.record.uuid)

      const teams = [
        ...state.teams.slice(0, idx),
        action.response.record,
        ...state.teams.slice(idx+1)
      ]

      return { ...state, teams }
    }
    case actions.CREATE_CREDENTIAL: {
      return { ...state, credentials: [action.response.record, ...state.credentials] }
    }
    case actions.CREATE_CREDENTIALS: {
      return { ...state, credentials: [...action.response.collection, ...state.credentials] }
    }
    case actions.GET_CREDENTIALS: {
      return { ...state, credentials: action.response.collection }
    }
    case actions.SET_KEY_SET: {
      return { ...state, keySet: action.keySet }
    }
    case actions.PRESENCE_SUBSCRIBE: {
      return {...state, presenceChannel: action.presenceChannel, privateChannel: action.privateChannel}
    }
    case actions.GET_TEAM_EVENTS: {
      return {...state, team_events: action.response.collection }
    }
    default:
      return state;
  }
}
