import React, { Component, Fragment } from 'react'
import { Spinner } from '@blueprintjs/core'
import styles from './emaillogin.module.scss'

export default class EmailLogin extends Component {
  state = {
    email: "",
    code: "",
    isSubmittingCode: false,
    isEmailSubmitted: false,
    uuid: null
  }

  codeInputRef = React.createRef();

  componentDidMount() {
    if (this.props.loginHint) {
      this.setState({email: this.props.loginHint})
    }
  }

  render() {
    const { error, isEmailSubmitted } = this.state
    
    return (
      <div className={ styles.emailForm }>
        { isEmailSubmitted ? this.renderCodeForm() : this.renderEmailForm() }

        { error && <div className={ styles.error }>{error}</div> }
      </div>
    )
  }

  renderEmailForm = () => {
    const { email, isSubmittingEmail } = this.state

    return isSubmittingEmail ? <Spinner /> : (
      <Fragment>
        <p>Or, continue with your work or personal email address:</p>

        <form className={ styles.form } onSubmit={ this.submitEmail }>
          <input
            id="email"
            className={ styles.input }
            onChange={ event => this.setState({email: event.target.value }) }
            placeholder="Enter your email address"
            value={ email } />
          <button
            className={ styles.button }
            onClick={ this.submitEmail }
          >Continue</button>
        </form>
      </Fragment>
    )
  }

  renderCodeForm = () => {
    const { code, isSubmittingCode } = this.state

    return isSubmittingCode ? <Spinner /> : (
      <Fragment>
        <p>We just sent you a temporary login code. Please check your inbox.</p>

        <form onSubmit={ this.submitCode }>
          <input
            ref={this.codeInputRef}
            id="code"
            className={ styles.input }
            onChange={ event => this.setState({code: event.target.value }) }
            placeholder="Paste login code"
            value={ code } />
          <button
            className={ styles.button }
            onClick={ this.submitCode }
          >Continue</button>
        </form>
      </Fragment>
    )
  }

  submitEmail = (event) => {
    const { email } = this.state
    const { sendCode } = this.props

    event.preventDefault()

    if (!RegExp("@").test(email)) {
      this.setState({
        isSubmittingEmail: false,
        error: "Please enter a valid email",
      })
    } else {
      this.setState({ isSubmittingEmail: true }, async () => {
        const { response, error } = await sendCode({ email })

        if (error) {
          this.setState({
            isSubmittingEmail: false,
            error: "Something went wrong. Please try again",
          })
        } else {
          this.setState({
            uuid: response.uuid,
            isEmailSubmitted: true,
            isSubmittingEmail: false,
            error: ""
          }, () => {
            const { current } = this.codeInputRef
            if (current) {
              current.focus()
            }
          })
        }
      })
    }
  }

  submitCode = (event) => {
    const { code, uuid } = this.state
    const { confirmCode } = this.props

    event.preventDefault()

    if (code.length === 0) {
      this.setState({
        error: "Please enter a valid code",
        isSubmittingCode: false
      })
    } else {
      this.setState({ isSubmittingCode: true }, async () => {
        const { response, error, headers } = await confirmCode({ uuid, code })

        if (error) {
          this.setState({
            error: "Your code is invalid or expired",
            isSubmittingCode: false
          })
        } else {
          this.setState({
            error: "",
            isSubmittingCode: false,
          }, () => {
            this.props.codeLogin({
              token: headers.authorization,
              response: response.extension
            })
          })
        }
      })
    }
  }
}
