import React, { Component } from 'react'
import { Redirect }         from "react-router-dom"
import { H1 }               from "@blueprintjs/core"

import Loader       from '../../components/Loader'
import Content      from '../../components/Content'
import TinyLayout   from '../../layouts/TinyLayout'
import auth         from '../../utils/auth'
import LoginButton  from '../../shared/LoginButton'

import styles from './styles.module.scss'


export default class Login extends Component {
  state = { redirectToReferrer: false, loader: false }

  componentDidMount() {
    const {match: {params: {application}}} = this.props;

    if (auth.getToken() && !application) {
      this.setState({ redirectToReferrer: true });
    }
  }

  render() {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    const {match: {params: {application}}} = this.props;
    let { redirectToReferrer, loader } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;

    if (loader) {
      return <Loader />
    }

    const buttonOptions = {
      application,
      loader: loader => this.setState({loader}),
      onLogin: this.onLogin,
    }

    const googleButton = <LoginButton
      {...buttonOptions}
      text="Continue with Google"
      provider="google"
      redirectUri="https://app.meetsidekick.com" />

    const msalButton = <LoginButton
      {...buttonOptions}
      provider="msal"
      text="Continue with Microsoft" />

    const emailButton = <LoginButton {...buttonOptions} provider="email" />

    const content = (
      <div className={ application === 'ext-onboarding' ? styles.loginViaExtension : styles.loginLayout }>
        <div className={ styles.googleButton }>{googleButton}</div>
        <div className={ styles.msalButton }>{msalButton}</div>
        <div className={ styles.emailLogin }>{emailButton}</div>

        <div className={ styles.terms }>
          By clicking “Continue with Google/Microsoft/Email” above, you acknowledge that
          you have read and understood, and agree to Sidekick's{' '}
          <a href="https://cdn.meetsidekick.com/Terms+of+Service.pdf" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>{' '}and{' '}
          <a href="https://cdn.meetsidekick.com/Privacy+Policy.pdf" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        </div>
      </div>
    )

    return application === 'ext-onboarding' ? content : (
      <TinyLayout>
        <Content renderTitle={ () => <H1>Login to Sidekick</H1> }>
          {content}
        </Content>
      </TinyLayout>
    )
  }

  onLogin = () => {
    this.setState({redirectToReferrer: true})
  }
}
