import React from 'react'

import TinyLayout  from '../../layouts/TinyLayout'
import Content from '../../components/Content'
import Loader  from '../../components/Loader'
import LoginButton  from '../../shared/LoginButton'

import auth from '../../utils/auth'

import styles from './invite.module.scss'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../redux/actions';

class Invite extends React.Component {
  state = { invite: null, loader: true }

  componentDidMount() {
    this.getInvite()
  }

  render() {
    const { loader, invite } = this.state

    if (loader) {
      return <Loader />
    } else {
      if (invite) {
        const { team, inviter, email } = this.state.invite

        return(
          <TinyLayout>
            <Content title={ `${inviter.name || 'Somebody'} has invited you` } subtitle={ `to join "${team.name}"` }>
              <p>
                { "Choose option to login for " }
                <strong>{ email }</strong>
              </p>
              <div className={ styles.buttons }>
                <div className={ styles.googleButton }>
                  <LoginButton
                    onLogin={ this.onLogin }
                    loginHint={ email }
                    text={ "Continue with Google" }
                    provider={ 'google' } />
                </div>
                <div className={ styles.msalButton }>
                  <LoginButton
                    onLogin={ this.onLogin }
                    loginHint={ email }
                    text={ "Continue with Office365" }
                    provider={ 'msal' }
                    redirectUri={ 'https://app.meetsidekick.com/invite' }/>
                </div>
                <div className={ styles.emailLogin }>
                  <LoginButton
                    loginHint={ email }
                    loader={ loader => this.setState({loader}) }
                    onLogin={ this.onLogin }
                    provider={ 'email' }/>
                </div>
              </div>
            </Content>
          </TinyLayout>
        )
      } else {
        return(
          <TinyLayout>
            <Content title={ "No invite found" } />
          </TinyLayout>
        )
      }
    }
  }

  getInvite = () => {
    const { match: { params: { id } } } = this.props

    auth.clearAll()

    this.props.getTeamInvite(id)
      .then(({response, error}) => {
        if (error) {
          this.setState({loader: false})
        } else {
          this.setState({loader: false, invite: response.record})
        }
      })
  }

  onLogin = () => {
    this.props.getMe()
      .then(this.accept)
  }

  accept = () => {
    const { match: { params: { id } }, selectTeam, addToast } = this.props

    this.props.acceptInvite(id)
      .then(({response, error}) => {
        if (error) {
          auth.clearAll()

          addToast({
            message: error.response.data.error || "Something went wrong",
            intent: 'DANGER',
            timeout: 5000
          })
        } else {
          selectTeam({team: response.record.team})
          window.history.pushState({}, '', '/')
          window.location.reload()
        }
      })
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch)
};

export default connect(null, mapDispatchToProps)(Invite)
