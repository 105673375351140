import React, { Component } from 'react'
import { GoogleLogin as OriginalGoogleLogin } from 'react-google-login'

import config                     from '../../config/config'
import { ReactComponent as Logo } from './google.svg'

import styles from './googlelogin.module.scss'

export default class GoogleLogin extends Component {
  render() {
    const { text, loginHint } = this.props

    return <OriginalGoogleLogin
      clientId={ config.GOOGLE_CLIENT_ID }
      onSuccess={ this.responseGoogle }
      onFailure={ this.responseGoogle }
      loginHint={ loginHint }
      prompt={ 'select_account' }
      render={ renderProps => (
        <button
          className={ styles.googleButton }
          onClick={
            () => {
              this.setState({loader: true})
              renderProps.onClick()
            }
          }>
          <Logo className={ styles.logo }/>
          <div>{ text || "Sign In with Google" } </div>
        </button>
      )}
      cookiePolicy={'single_host_origin'}
    />
  }

  responseGoogle = (response) => {
    if (response.error) {
      console.log("Google error:", response.error)
    } else {
      const idToken = response.getAuthResponse().id_token

      if (idToken.length !== 0) {
        this.props.onClick(idToken)
      }
    }
  }
}
