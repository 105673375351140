import * as actions from "../actionTypes";

const initialState = {
  recipes: [],
  store: [],
  applications: [],
  workspaces: [],
  resources: [],
  roles: []
};

const applicationWithRoles = function(application, state) {
  application.roles = state.roles
    .filter(role => application.access_rules
      .filter(item => item.type === "role")
      .map(item => item.id)
      .includes(role.id)
    )
  return application
}

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.LOGOUT: {
      return state
    }
    case actions.LOAD_APPS: {
      return { ...state, recipes: action.response.collection }
    }
    case actions.LOAD_APPLICATIONS: {
      const applications = action.collection
        .map(application => applicationWithRoles(application, state))

      return { ...state, applications }
    }
    case actions.CREATE_APPLICATION: {
      const application = applicationWithRoles(action.response.record, state)

      return { ...state, applications: [application, ...state.applications] }
    }
    case actions.UPDATE_APPLICATION: {
      const application = applicationWithRoles(action.response.record, state)

      let idx = state.applications.findIndex(app => app.id === application.id)

      const applications = [
        ...state.applications.slice(0, idx),
        application,
        ...state.applications.slice(idx+1)
      ]

      return { ...state, applications }
    }
    case actions.CREATE_WORKSPACE: {
      return { ...state, workspaces: [action.response.record, ...state.workspaces] }
    }
    case actions.DELETE_WORKSPACE: {
      let idx = state.workspaces.findIndex(workspaces => workspaces.id === action.response.record.id)

      const workspaces = [
        ...state.workspaces.slice(0, idx),
        ...state.workspaces.slice(idx+1)
      ]

      return { ...state, workspaces }
    }
    case actions.UPDATE_WORKSPACE: {
      let idx = state.workspaces.findIndex(ws => ws.id === action.response.record.id)

      const workspaces = [
        ...state.workspaces.slice(0, idx),
        action.response.record,
        ...state.workspaces.slice(idx+1)
      ]

      return { ...state, workspaces }
    }
    case actions.GET_WORKSPACES: {
      return { ...state, workspaces: action.response.collection }
    }
    case actions.GET_ROLES: {
      return { ...state, roles: action.response.collection }
    }
    case actions.CREATE_ROLE: {
      const newState = { ...state, roles: [action.response.record, ...state.roles] }

      return newState
    }
    case actions.UPDATE_ROLE: {
      let idx = state.roles.findIndex(roles => roles.uuid === action.response.record.uuid)

      const roles = [
        ...state.roles.slice(0, idx),
        action.response.record,
        ...state.roles.slice(idx+1)
      ]

      const newState = { ...state, roles }

      const applications = state.applications
        .map(application => applicationWithRoles(application, newState))

      return { ...newState, applications}
    }
    case actions.DELETE_ROLE: {
      let idx = state.roles.findIndex(role => role.uuid === action.response.record.uuid)

      const roles = [
        ...state.roles.slice(0, idx),
        ...state.roles.slice(idx+1)
      ]

      return { ...state, roles }
    }
    case actions.GET_RESOURCES: {
      return { ...state, resources: action.response.collection }
    }
    case actions.CREATE_RESOURCE: {
      return { ...state, resources: [action.response.record, ...state.resources] }
    }
    case actions.UPDATE_RESOURCE: {
      let idx = state.resources.findIndex(resource => resource.uuid === action.response.record.uuid)

      const resources = [
        ...state.resources.slice(0, idx),
        action.response.record,
        ...state.resources.slice(idx+1)
      ]

      return { ...state, resources }
    }
    case actions.DELETE_RESOURCE: {
      let idx = state.resources.findIndex(({uuid}) => uuid === action.response.record.uuid)

      const resources = [
        ...state.resources.slice(0, idx),
        ...state.resources.slice(idx+1)
      ]

      return { ...state, resources }
    }
    default:
      return state;
  }
}
