import React from "react";
import {Icon} from "@blueprintjs/core";
import styles from "./participantListItem.module.scss";

const ParticipantListItem = ({online, name, image, showStatus = true}) => {
  return (
    <div className={styles.container}>
      {
        image
          ? <img alt={name} className={styles.image} src={image}/>
          : <div className={styles.imageEmpty}>
            <Icon icon={'person'} iconSize={16}/>
          </div>
      }
      <span className={'bp3-ui-text bp3-text-overflow-ellipsis ' + styles.name} title={name}>{name}</span>
      {
        showStatus &&
        <div className={'bp3-ui-text ' + styles.status}>
          {
            online
              ? <span className={styles.statusOnline}>Online</span>
              : <span className={styles.statusOffline}>Offline</span>
          }
        </div>
      }
    </div>
  )
};

export default ParticipantListItem