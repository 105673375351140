import * as actions from "../actionTypes";

const initialState = {};

const formatParticipants = (participants) => participants.reduce((acc, item) => {
  acc[item.id] = item

  return acc
}, {})

export default function (state = initialState, action) {
  switch (action.type) {
    case actions.SAVE_ROOM: {
      return {
        ...state,
        [action.data.roomId]: {
          ...action.data,
          participants: formatParticipants(action.data.participants)
        }
      }
    }
    case actions.REFRESH_ROOM_PARTICIPANTS: {
      if (!state.hasOwnProperty(action.roomId)) return state

      const room = {
        ...state[action.roomId],
        participants: formatParticipants(action.participants)
      }

      return {
        ...state,
        [action.roomId]: room
      }
    }
    default:
      return state;
  }
}
