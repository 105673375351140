import React from 'react'

import Loader  from '../../components/Loader'

import auth from '../../utils/auth'

export default class TeamLogin extends React.Component {
  state = { invite: null, loader: true }

  componentDidMount() {
    this.login()
  }

  render() {
    return <Loader />
  }

  login = () => {
    const { match: { params: { teamUUID, token } } } = this.props

    auth.setToken(token)
    auth.saveTeamUUID(teamUUID)

    window.location = '/'
  }
}