import React from 'react'
import { Redirect } from "react-router-dom"

import TinyLayout  from '../../layouts/TinyLayout'
import Content from '../../components/Content'
import Loader  from '../../components/Loader'


import LoginButton from '../../shared/LoginButton'
import auth from '../../utils/auth'

import styles from './register.module.scss'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../redux/actions';

class Register extends React.Component {
  state = { invite: null, loader: true }

  componentDidMount() {
    this.getInvite()
  }

  render() {
    const { loader, invite, redirectToReferrer } = this.state

    if (redirectToReferrer) return <Redirect to={ "/" } />;

    if (loader) {
      return <Loader />
    } else {
      if (invite) {
        const { inviter, email } = this.state.invite

        return (
          <TinyLayout>
            <Content
              title={ `${inviter.name} has invited you` }
              subtitle={ `to join Sidekick` }>
              <p>
                { "Choose option to login for " }
                <strong>{ email }</strong>
              </p>
              <div className={ styles.buttons }>
                <div className={ styles.googleButton }>
                  <LoginButton
                    onLogin={ this.onLogin }
                    loginHint={ email }
                    text={ "Continue with Google" }
                    provider={ 'google' } />
                </div>
                <div className={ styles.msalButton }>
                  <LoginButton
                    onLogin={ this.onLogin }
                    loginHint={ email }
                    text={ "Continue with Office365" }
                    provider={ 'msal' } />
                </div>
                <div className={ styles.emailLogin }>
                  <LoginButton
                    onLogin={ this.onLogin }
                    provider={ 'email' }
                    loginHint={ email }/>
                </div>
              </div>
            </Content>
          </TinyLayout>
        )
      } else {
        return (
          <TinyLayout>
            <Content title={ "No invite found" } />
          </TinyLayout>
        )
      }
    }
  }

  onLogin = (token) => {
    auth.setToken(token)
    this.setState({ redirectToReferrer: true })
  }

  getInvite = () => {
    const { match: { params: { id } } } = this.props

    auth.clearAll()

    this.props.getInvite(id)
      .then(({response, error}) => {
        if (error) {
          console.log("google login error", error)
          this.setState({loader: false})
        } else {
          this.setState({loader: false, invite: response.record})
        }
      })
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(actions, dispatch)
};

export default connect(null, mapDispatchToProps)(Register)
