import React, { Component } from 'react'
import * as Msal from 'msal'

import config                         from '../../config/config'
import { ReactComponent as MsalLogo } from './microsoft.svg'

import styles from './msallogin.module.scss'

export default class MsalButton extends Component {
  componentDidMount() {
    const { redirectUri } = this.props

    const msalConfig = {
      auth: {
        clientId: config.MSAL_CLIENT_ID
      }
    }

    if (redirectUri) {
      msalConfig.auth.redirectUri = redirectUri
    }
    this.msalInstance = new Msal.UserAgentApplication(msalConfig)
  }

  render() {
    const { text } = this.props

    return(
      <button
        className={ styles.msalButton }
        onClick={ this.onClick }>
        <MsalLogo className={ styles.logo }/>
        <div>{ text }</div>
      </button>
    )
  }

  onClick = () => {
    const { loginHint } = this.props

    const request = {
      scopes: ['user.read', 'email', 'openid', 'profile']
    }

    if (loginHint) {
      request['loginHint'] = loginHint
    } else {
      request['prompt'] = 'select_account'
    }

    return this.msalInstance.loginPopup(request)
      .then(
        idToken => this.props.onClick(idToken.idToken.rawIdToken),
        err => console.log("Msal Error:", err)
      )
  }
}
