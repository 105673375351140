import Pusher from 'pusher-js'
import * as actions from "./actionTypes"
import auth from '../utils/auth'
import config from '../config/config'
import requestApi from '../utils/request'
import {persistor} from './store'

export const bindPusher = (me) => {
  const pusher = new Pusher(config.PUSHER_KEY, {
    cluster: 'us2',
    encrypted: true,
    authEndpoint: config.BACKEND_HOST + "session/pusher",
    auth: {
      headers: {
        Authorization: `Bearer ${auth.getToken()}`,
        'x-team-uuid': auth.getTeamUUID()
      }
    }
  })

  const privateChannel = pusher.subscribe(`private-app-${me.id}`)

  const presenceChannel = pusher.subscribe(`presence-team-${auth.getTeamUUID()}`)

  return({ type: actions.PRESENCE_SUBSCRIBE, presenceChannel, privateChannel })
}

const dispatchWithError = (dispatch, type, data, error) => {
  if (error) {
    return dispatch({type: actions.ERROR, error})
  } else {
    return dispatch({type, response: data.response, headers: data.headers})
  }
}

export const logout = () => {
  return async (dispatch) => {
    auth.clearAll()
    window.history.pushState({}, '', '/')
    window.location.reload()
    await persistor.purge()
    return dispatch({ type: actions.LOGOUT })
  }
}

export const selectTeam = ({team}) => {
  return async (dispatch) => {
    auth.saveTeamUUID(team ? team.uuid : null)
    await persistor.purge()
    return dispatch({ type: actions.LOGOUT })
  }
}

export const chooseTeam = ({team}) => {
  return async (dispatch) => {
    auth.saveTeamUUID(team.uuid)
    return dispatch({ type: actions.CHOOSE_TEAM, team })
  }
}

export const createTeam = ({name}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team', method: 'post', data: { name } })

    return dispatchWithError(dispatch, actions.CREATE_TEAM, data, error)
  }
}

export const sendCode = ({email}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'session/direct/send_code', method: 'post', data: { email } })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.SEND_CODE, response: data.response })
    }
  }
}

export const confirmCode = ({uuid, code}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'session/direct/confirm_code', method: 'post', data: { uuid, code } })

    if (error) {
      return dispatch({type: actions.ERROR, error: error, response: error.response.data})
    } else {
      return dispatch({ type: actions.CONFIRM_CODE, response: data.response, headers: data.headers })
    }
  }
}

export const login = ({token, provider}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'session/provider', method: 'post', data: { token, provider } })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      dispatch({ type: actions.LOGIN, response: data.response })
      return data
    }
  }
}

export const loginExtension = ({ token, provider }) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'session/provider', app: 'desktop', method: 'post', data: { token, provider } })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return data
    }
  }
}

export const loadProfile = () => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'profile' })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.LOAD_PROFILE, response: data.response })
    }
  }
}

export const getTeam = () => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team' })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.GET_TEAM, response: data.response })
    }
  }
}

export const getMe = () => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'me' })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.GET_ME, response: data.response })
    }
  }
}

export const loadApps = () => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'apps' })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.LOAD_APPS, response: data.response })
    }
  }
}

export const getMemberApplications = (member) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/members/${member.uuid}/applications` })

    return dispatchWithError(dispatch, actions.GET_MEMBER_APPLICATIONS, data, error)
  }
}

export const deleteMember = (uuid) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/members/${uuid}`, method: "DELETE" })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.DELETE_MEMBER, response: data.response })
    }
  }
}

export const updateMember = (uuid, attributes) => {
  return async (dispatch) => {
    let formData

    if (attributes.avatar_data) {
      formData = new FormData()
      for ( var key in attributes ) {
        if (key === 'avatar_data') {
          formData.append('avatar', attributes[key])
        } else {
          formData.append(key, attributes[key])
        }
      }

    } else {
      formData = attributes
    }

    const { error, data } = await requestApi({ url: `team/members/${uuid}`, method: "PATCH", data: formData })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.UPDATE_MEMBER, response: data.response })
    }
  }
}

export const loadTeamApps = () => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/applications' })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.LOAD_APPLICATIONS, collection: data.response.collection })
    }
  }
}

export const getMemberWorkspaces = (member) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/members/${member.uuid}/workspaces` })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.GET_MEMBER_WORKSPACES, response: data.response })
    }
  }
}

export const createApplication = (attributes) => {
  return async (dispatch) => {
    let formData

    if (attributes.icon_data) {
      formData = new FormData()
      for ( var key in attributes ) {
        if (!attributes[key]) {
          continue
        }
        if (key === 'icon_data') {
          formData.append('icon', attributes[key])
        } else {
          formData.append(key, attributes[key])
        }
      }

    } else {
      formData = attributes
    }

    const { error, data } = await requestApi({ url: 'team/applications',  method: "POST", data: formData})

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.CREATE_APPLICATION, response: data.response })
    }
  }
}

export const deleteApplication = (application_id) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/applications/${application_id}`,  method: "DELETE" })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.UPDATE_APPLICATION, response: data.response })
    }
  }
}

export const updateApplication = (application, attributes) => {
  return async (dispatch) => {
    let formData

    if (attributes.icon_data) {
      formData = new FormData()
      for ( var key in attributes ) {
        if (!attributes[key]) {
          continue
        }
        if (key === 'icon_data') {
          formData.append('icon', attributes[key])
        } else if (key === 'credential_access') {
          formData.append('credential_access_string', JSON.stringify(attributes[key]))
        } else if (key === 'access_rules') {
          formData.append('access_rules_string', JSON.stringify(attributes[key]))
        } else {
          formData.append(key, attributes[key])
        }
      }

    } else {
      formData = attributes
    }

    const { error, data } = await requestApi({ url: `team/applications/${application.id}`,  method: "PATCH", data: formData })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.UPDATE_APPLICATION, response: data.response })
    }
  }
}

export const installApplication = ({workspace_id, application_id, member_id, name}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/applications/${application_id}/install`,  method: "POST", data: { member_id, workspace_id, name }})

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.UPDATE_APPLICATION, response: data.response })
    }
  }
}

export const revokeApplication = ({application_id, member_id}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/applications/${application_id}/revoke`,  method: "POST", data: { member_id }})

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.UPDATE_APPLICATION, response: data.response })
    }
  }
}

// INVITES

export const inviteMember = ({email, roles, admin}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/invites', method: 'post', data: { email, roles, admin } })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.INVITE_MEMBER, response: data.response })
    }
  }
}

export const getTeamInvite = (id) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/invites/${id}`, method: 'get' })

    if (error) {
      if (error.message === "Network Error") {
        dispatch(addToast({
          message: "Network Error. Please try again later",
          intent: 'DANGER',
          timeout: 5000
        }))
      }
      return dispatch({type: actions.ERROR, error: error})
    } else {
      dispatch({ type: actions.GET_TEAM_INVITE, response: data.response })
      return data
    }
  }
}

export const getInvite = (id) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `invites/${id}`, method: 'get' })

    return dispatchWithError(dispatch, actions.GET_INVITE, data, error)
  }
}

export const acceptInvite = (id) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/invites/${id}/accept`, method: 'post' })

    return dispatchWithError(dispatch, actions.GET_TEAM_INVITE, data, error)
  }
}

export const updateTeam = (params) => {
  return async (dispatch) => {
    let formData

    if (params.logo_data) {
      formData = new FormData()
      for ( var key in params ) {
        if (key === 'logo_data') {
          formData.append('logo', params[key])
        } else {
          formData.append(key, params[key])
        }
      }

    } else {
      formData = params
    }

    const { error, data } = await requestApi({ url: 'team', method: 'PATCH', data: formData })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.UPDATE_TEAM, response: data.response })
    }
  }
}

export const getResources = () => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/resources', method: 'get' })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.GET_RESOURCES, response: data.response })
    }
  }
}

export const reorderResources = (collection) => {
  return ({ type: actions.GET_RESOURCES, response: { collection } })
}

export const createResource = ({name, url}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/resources', method: 'post', data: { name, url } })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.CREATE_RESOURCE, response: data.response })
    }
  }
}

export const deleteRole = (role) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/roles/${role.uuid}`, method: 'delete' })

    return dispatchWithError(dispatch, actions.DELETE_ROLE, data, error)
  }
}

export const updateResource = (resource, params) => {
  return async (dispatch) => {
    let formData

    if (params.icon) {
      formData = new FormData()

      formData.append('icon', params.icon)
    } else {
      formData = params
    }

    const { error, data } = await requestApi({ url: `team/resources/${resource.uuid}`, method: 'patch', data: formData })

    return dispatchWithError(dispatch, actions.UPDATE_RESOURCE, data, error)
  }
}

export const deleteResource = (resource) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/resources/${resource.uuid}`, method: 'DELETE' })

    return dispatchWithError(dispatch, actions.DELETE_RESOURCE, data, error)
  }
}

export const getRoles = () => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/roles', method: 'get' })

    return dispatchWithError(dispatch, actions.GET_ROLES, data, error)
  }
}

export const createRole = ({name}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/roles', method: 'post', data: { name } })

    return dispatchWithError(dispatch, actions.CREATE_ROLE, data, error)
  }
}

export const updateRole = (uuid, attributes) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/roles/${uuid}`, method: 'PATCH', data: attributes })

    return dispatchWithError(dispatch, actions.UPDATE_ROLE, data, error)
  }
}

export const createWorkspace = ({name, sandbox}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/workspaces', method: 'post', data: { name, sandbox } })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.CREATE_WORKSPACE, response: data.response })
    }
  }
}

export const updateWorkspace = (id, attributes) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/workspaces/${id}`, method: 'PATCH', data: attributes })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.UPDATE_WORKSPACE, response: data.response })
    }
  }
}

export const revokeWorkspace = (id, memberId) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/workspaces/${id}/revoke`, method: 'POST', data: { member_ids: [memberId] } })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.UPDATE_WORKSPACE, response: data.response })
    }
  }
}

export const deleteWorkspace = (id) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/workspaces/${id}`, method: 'DELETE' })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.DELETE_WORKSPACE, response: data.response })
    }
  }
}

export const installWorkspace = (id, memberId) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/workspaces/${id}/install`, method: 'POST', data: { member_ids: [memberId] } })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.UPDATE_WORKSPACE, response: data.response })
    }
  }
}

export const getWorkspaces = () => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/workspaces', method: 'get' })

    if (error) {
      return dispatch({type: actions.ERROR, error: error})
    } else {
      return dispatch({ type: actions.GET_WORKSPACES, response: data.response })
    }
  }
}

export const getActivity = ({from, to, period}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/activity', method: 'get', params: { from, to, period } })

    return dispatchWithError(dispatch, actions.GET_ACTIVITY, data, error)
  }
}

export const updateActivity = ({activity}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/activity', method: 'patch', data: { activity } })

    return dispatchWithError(dispatch, actions.UPDATE_ACTIVITY, data, error)
  }
}

export const getProductivity = ({from, to, period}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/productivity', method: 'get', params: { from, to, period } })

    return dispatchWithError(dispatch, actions.GET_ACTIVITY, data, error)
  }
}

export const getProductivityStats = ({from, to, period}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/productivity/stats', method: 'get', params: { from, to, period } })

    return dispatchWithError(dispatch, actions.GET_ACTIVITY, data, error)
  }
}

export const getProductivitySettings = () => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/productivity/settings', method: 'get' })

    return dispatchWithError(dispatch, actions.GET_ACTIVITY, data, error)
  }
}

export const updateSiteCategory = ({site, category}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/productivity/category', method: 'patch', data: { site, category } })

    return dispatchWithError(dispatch, actions.UPDATE_SITE_CATEGORY, data, error)
  }
}

export const getOnline = (time) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/activity/online', method: 'get', params: { time } })

    return dispatchWithError(dispatch, actions.GET_ACTIVITY, data, error)
  }
}

export const getKeySet = (recovery_key) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'key_set', method: 'get', params: { recovery_key } })

    return dispatchWithError(dispatch, actions.GET_KEY_SET, data, error)
  }
}

export const setKeySet = (keySet) => {
  return { type: actions.SET_KEY_SET, keySet }
}

export const createKeySet = (params) => {
  console.log("createKeySet", params)

  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'key_set', method: 'post', data: params })

    return dispatchWithError(dispatch, actions.CREATE_KEY_SET, data, error)
  }
}

export const createCredential = (params) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'credentials', method: 'post', data: params })

    return dispatchWithError(dispatch, actions.CREATE_CREDENTIAL, data, error)
  }
}

export const shareCredentials = (params) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'credentials/share', method: 'post', data: params })

    return dispatchWithError(dispatch, actions.SHARE_CREDENTIALS, data, error)
  }
}

export const getCredentials = (publicKeyId) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'credentials', method: 'get', params: { public_key_id: publicKeyId } })

    return dispatchWithError(dispatch, actions.GET_CREDENTIALS, data, error)
  }
}

export const getPendingCredentialRequests = (publicKeyId) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'credentials/pending_requests', method: 'get', params: { public_key_id: publicKeyId } })

    return dispatchWithError(dispatch, actions.ACTION, data, error)
  }
}

export const requestCredentialAccess = ({publicKeyId}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: '/credentials/request_new', method: 'post', data: { public_key_id: publicKeyId } })

    return dispatchWithError(dispatch, actions.CREATE_CREDENTIALS, data, error)
  }
}

// export const requestCredentialAccess = ({credentialId, publicKeyId}) => {
//   return async (dispatch) => {
//     const { error, data } = await requestApi({ url: '/credentials/request_access', method: 'post', data: { public_key_id: publicKeyId, credential_id: credentialId } })

//     return dispatchWithError(dispatch, actions.CREATE_CREDENTIAL, data, error)
//   }
// }

export const createNotification = (message) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/notifications', method: 'post', data: { message } })

    return dispatchWithError(dispatch, actions.CREATE_NOTIFICATION, data, error)
  }
}

export const getNotifications = (message) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/notifications', method: 'get' })

    return dispatchWithError(dispatch, actions.GET_NOTIFICATIONS, data, error)
  }
}

export const joinRoom = ({roomId, inviteId}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: `team/rooms/${roomId}/join/${inviteId}`, method: 'get' })

    return dispatchWithError(dispatch, actions.JOIN_ROOM, data, error)
  }
}

export const createSite = ({url}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'sites', method: 'post', data: { url } })

    return dispatchWithError(dispatch, actions.CREATE_SITE, data, error)
  }
}

export const getTeamEvents = ({per_page, page}) => {
  return async (dispatch) => {
    const { error, data } = await requestApi({ url: 'team/events', params: { page, per_page }})

    return dispatchWithError(dispatch, actions.GET_TEAM_EVENTS, data, error)
  }
}

export const saveRoom = (data) => {
  return { type: actions.SAVE_ROOM, data}
}

export const refreshRoomParticipants = (roomId, participants) => {
  return { type: actions.REFRESH_ROOM_PARTICIPANTS, roomId, participants}
}

export const addToast = (toast) => {
  return({ type: actions.ADD_TOAST, toast })
}
