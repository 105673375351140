import React, { Component } from 'react';
import styles from './styles.module.scss';

export default class Loader extends Component {
  render() {
    return (
      <div className={ styles.container }>
        <div className={ styles["lds-ripple"] }><div></div><div></div></div>
      </div>
    )
  }
}


