import React, { Component, Fragment } from "react";
import { Switch, Route, withRouter } from "react-router-dom"

import PrivateRoute from '../PrivateRoute'
import Login        from '../Login'
import Invite       from '../Invite'
import Register     from '../Register'
import NotFound     from '../NotFound'
import TeamLogin    from '../TeamLogin'
import Room         from '../Room'
import RoomLobby    from '../RoomLobby'
import Downloads    from '../Downloads'
import GoogleDrive   from '../GoogleDrive'

import { Toaster, Position, Toast, Intent } from '@blueprintjs/core'

import { connect }   from 'react-redux'

const Main = React.lazy(() => import('../Main'));

class ToastComponent extends Component {
  state = { toasts: [] }

  refHandlers = {
    toaster: (ref) => this.toaster = ref
  }

  componentDidUpdate(prevProps) {
    if (prevProps.toast !== this.props.toast) {
      this.addToast(this.props.toast)
    }
  }

  render() {
    return(
      <Toaster position={Position.TOP_CENTER} ref={this.refHandlers.toaster}>
        { this.state.toasts.map(toast => <Toast {...toast} />) }
      </Toaster>
    )
  }

  addToast = (toast) => {
    const { message, intent, timeout } = toast

    this.toaster.show({
      message,
      intent: Intent[intent],
      timeout: timeout || 2000
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  const { toast } = state.main

  return { toast, ...ownProps }
}

const ConnectedToastComponent = connect(mapStateToProps, null)(ToastComponent)


class App extends Component {
  render() {
    return (
      <Fragment>
        <Switch>
          <Route path="/login/:application?" component={Login} exact/>
          <Route path="/invite/:id" component={Invite} exact/>
          <Route path="/team/:teamUUID/login/:token" component={TeamLogin} exact/>
          <Route path="/register/:id" component={Register} exact/>
          <Route path="/rooms/:roomId" component={Room} exact/>
          <Route path="/rooms/:roomId/join/:inviteId" component={RoomLobby} exact/>
          <Route path="/downloads" component={ Downloads } exact/>
          <Route path="/google_drive" component={ GoogleDrive } exact />
          <Route path="/🤷‍♀️" exact component={NotFound} />
          <PrivateRoute path="/" component={Main} />
        </Switch>
        <ConnectedToastComponent />
      </Fragment>
    )
  }
};

export default withRouter(App);
