import React from 'react'

import { Navbar } from "@blueprintjs/core";

import styles from './downloads.module.scss'
import logo from './logo.png'
import apple from './apple.png'
import windows from './windows.png'
import linux from './linux.png'

export default class Downloads extends React.Component {
  render() {
    return (
      <div className={ styles.container }>
        <Navbar className={ styles.bar }>
          <Navbar.Heading className={ styles.heading }>
            <div style={{ backgroundImage: `url("${ logo }")` }} className={ styles.logo }/>
            <span className={ styles.title }>
              Sidekick
            </span>
          </Navbar.Heading>
        </Navbar>


        <div className={ styles.content }>
          <h1>Get a full-featured browser designed for a modern work</h1>
          <div className={ styles.links }>
            <div className={ styles.group }>
              <h2>Get for Windows</h2>
              <a className={ styles.link } href="https://api.meetsidekick.com/downloads/win">
                <div style={{ backgroundImage: `url("${ windows }")` }} className={ styles.apple }/>
                <div className={ styles.text }>
                  <div className={ styles.title2 }>
                    Download
                  </div>
                </div>
              </a>
            </div>
            <div className={ styles.group }>
              <h2>Get for Mac</h2>
              <a className={ styles.link } href="https://api.meetsidekick.com/downloads/mac">
                <div style={{ backgroundImage: `url("${ apple }")` }} className={ styles.apple }/>
                <div className={ styles.text }>
                  <div className={ styles.title2 }>
                    Download
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className={ styles.links }>
            <div className={ styles.group }>
              <h2>Other platforms</h2>
              <a className={ styles.link } href="https://api.meetsidekick.com/downloads/linux/deb">
                <div style={{ backgroundImage: `url("${ linux }")` }} className={ styles.apple }/>
                <div className={ styles.text }>
                  <div className={ styles.title2 }>
                    Linux (.deb)
                  </div>
                </div>
              </a>
              <a className={ styles.link } href="https://api.meetsidekick.com/downloads/linux/rpm">
                <div style={{ backgroundImage: `url("${ linux }")` }} className={ styles.apple }/>
                <div className={ styles.text }>
                  <div className={ styles.title2 }>
                    Linux (.rpm)
                  </div>
                </div>
              </a>
              <a className={ styles.link } href="https://api.meetsidekick.com/downloads/win86">
                <div style={{ backgroundImage: `url("${ windows }")` }} className={ styles.apple }/>
                <div className={ styles.text }>
                  <div className={ styles.title2 }>
                    Windows 32-bit
                  </div>
                </div>
              </a>
            </div>
            <div className={ styles.group } />
          </div>
        </div>
      </div>
    )
  }
}
