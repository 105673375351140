import React from 'react'
import styles from './googledrive.module.scss'
import { MenuItem, KeyCombo } from "@blueprintjs/core";
import { Omnibar } from "@blueprintjs/select";
import { GlobalHotKeys } from "react-hotkeys";

import moment from 'moment'

const keyMap = {
  OMNIBOX: "command+/"
};


class GoogleDrive extends React.Component {
  state = {
    loggedIn: false,
    files: [],
    search: true,
    isOpen: false
  }

  componentDidMount () {
    const script = document.createElement("script");

    script.src = "https://apis.google.com/js/api.js";
    script.async = true;
    script.defer = true;
    script.onload = this.onload

    document.body.appendChild(script);
  }

  loadFiles = (query) => {
    if (query.toString() === '') {
      this.setState({files: []})
      return
    }

    this.setState({search: true, files: []}, () => {
      let params = {
        'pageSize': 100,
        'fields': "*",
      }

      if (query) {
        params['q'] = `(fullText contains '${query}' or name contains '#${query}')`
      }

      window.gapi.client.drive.files.list(params)
        .then((response) => {
          this.setState({files: response.result.files, search: false })
        });
    })


  }

  onload = () => {
    window.gapi.load('client:auth2', this.initClient)
  }

  initClient = () => {
    window.gapi.client.init({
      apiKey: 'AIzaSyA082h1RVlDMQGslwpgON-SojxL74hQjNU',
      clientId: '303624157879-bmpti3tdo04a2ctivi1vvfjvj3di5gjt.apps.googleusercontent.com',
      discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
      scope: 'https://www.googleapis.com/auth/drive.metadata.readonly'
    }).then(this.onInit, this.onInitError)
  }

  onInit = () => {
    window.gapi.auth2.getAuthInstance().isSignedIn.listen(this.updateSigninStatus);
    this.updateSigninStatus(window.gapi.auth2.getAuthInstance().isSignedIn.get());
  }

  onInitError = (error) => {
    console.log(JSON.stringify(error, null, 2))
  }

  updateSigninStatus = (isSignedIn) => {
    // console.log(instance)
    // console.log(instance.currentUser)

    // const isSignedIn = instance.isSignedIn.get()

    if (isSignedIn) {
      this.setState({loggedIn: true})
    } else {
      this.setState({loggedIn: false})
    }
  }

  handleAuthorizeClick = (event) => {
    window.gapi.auth2.getAuthInstance().signIn();
  }

  handleSignOutClick = (event) => {
    this.setState({files: [], loggedIn: false}, () => { window.gapi.auth2.getAuthInstance().signOut() })
  }


  render() {
    const handlers = {
      OMNIBOX: event => this.handleToggle()
    };

    return (
      <GlobalHotKeys keyMap={keyMap} handlers={handlers}>
        <div className={ styles.container }>
          { this.state.loggedIn ? this.renderOmnibar() : this.loginButton() }
        </div>
      </GlobalHotKeys>
    )
  }

  renderOmnibar = () => {
    return(
      <div>
        <div style={{ marginBottom: 36 }}>
          <KeyCombo combo="command+/" />
        </div>
        { this.logoutButton() }
        <Omnibar
          isOpen={ this.state.isOpen }
          className={ styles.omnibar }
          onQueryChange={ this.onQueryChange }
          allowCreate={ false }
          resetOnSelect={ true }
          items={ this.state.files }
          itemRenderer={ (item, { handleClick, modifiers, query }) => this.itemRenderer(item, { handleClick, modifiers, query }) }
          itemPredicate={ (query, item) => this.itemPredicate(query, item) }
          noResults={ <MenuItem disabled={true} text={ this.state.search ? "Searching..." : "No results." } />}
          onItemSelect={this.handleItemSelect}
          onClose={ () => this.setState({ isOpen: false }) }
        />
      </div>
    )
  }

  handleToggle = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  itemPredicate = (query, item) => {
    return true
    // const normalizedTitle = film.title.toLowerCase();
    // const normalizedQuery = query.toLowerCase();

    // if (exactMatch) {
    //   return normalizedTitle === normalizedQuery;
    // } else {
    //   return `${film.rank}. ${normalizedTitle} ${film.year}`.indexOf(normalizedQuery) >= 0;
    // }
  }

  itemRenderer = (item, { handleClick, modifiers, query }) => {
    return <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      label={ item.mimeType }
      onClick={handleClick}
      key={ item.id }
      text={ <div className={ styles.file }><img src={ item.iconLink } size="16x16" alt="icon"/><span className={ styles.info }>{ item.name }</span></div> }
    />
  }

  handleItemSelect = (item) => {
    window.open(item.webViewLink)
  };

  onQueryChange = (query) => {
    this.timer && clearTimeout(this.timer)
    this.timer = setTimeout(() => { this.loadFiles(query) } , 250)
    return true
  }

  renderFiles() {
    console.log(this.state.files)
    const files = this.state.files.map((file,i) => {
      return(
        <li key={ i }>
          <div className={ styles.file }>
            <img src={ file.iconLink } size="16x16" alt="icon"/>
            <div className={ styles.info }>
              <a href={ file.webViewLink }>{ file.name }</a>
              <span className={ styles.viewed }>{ `Last viewed: ${moment(file.viewedByMeTime).format('L LT')}` }</span>
            </div>
          </div>
        </li>)
    })

    return <div><ul>{ files }</ul>{ this.logoutButton() }</div>
  }

  loginButton() {
    return <button onClick={ this.handleAuthorizeClick }>Authorize Google Drive</button>
  }

  logoutButton() {
    return <button onClick={ this.handleSignOutClick }>Disconnect</button>
  }
}

export default GoogleDrive