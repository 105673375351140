import React from 'react'
import cn from 'classnames'

import styles from './tinylayout.module.scss'

export default function Tiny({children}) {
  return (
    <div className={ styles.container }>
      <div className={ cn(styles.content, styles.small) }>
        { children }
      </div>
    </div>
  )
}

