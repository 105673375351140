import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Alert, Intent, Spinner} from "@blueprintjs/core";
import {joinRoom, saveRoom} from "../../redux/actions";
import auth from "../../utils/auth";
import styles from './room.module.scss';

class RoomLobby extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
    }
  }


  componentDidMount() {
    this.connect()
  }

  connect = () => {
    const {match: {params: {inviteId, roomId}}, joinRoom, saveRoom, history} = this.props;

    joinRoom({roomId, inviteId})
      .then((res) => {
        if (res.error) return Promise.reject(res.error);
        if (res.response.record.status === 'completed') return Promise.reject('The room has been closed')

        if (!auth.getToken()) {
          auth.setToken(res.headers.authorization)
        }

        saveRoom({
          roomId,
          inviteId,
          participants: res.response.record.participants,
          twilioSid: res.response.record.twilio_sid,
          twilioToken: res.headers.access_token
        })

        history.push({
          pathname: `/rooms/${roomId}`
        })
      })
      .catch(err => this.setState({error: err.message}))

  }

  render() {
    const { error } = this.state

    return (
      <div className={styles.layout}>
        <Spinner intent={Intent.PRIMARY} size={100}/>
        <Alert
          canEscapeKeyCancel={false}
          canOutsideClickCancel={false}
          confirmButtonText="Close"
          isOpen={error}
          onClose={() => this.setState({error: ''})}
        >
          <p>
            {error}
          </p>
        </Alert>
      </div>
    )
  }
}

const mapStateToProps = state => state.main.rooms;

export default connect(mapStateToProps, {joinRoom, saveRoom})(RoomLobby);