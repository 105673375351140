import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import './index.css';
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/browser'
import { BrowserRouter as Router, Route } from "react-router-dom"
import { withRouter } from "react-router-dom"
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import store, {persistor} from './redux/store'
import App   from './containers/App';


const rootElement = document.getElementById('root')

if (process.env.REACT_APP_SENTRY_ENV) {
  Sentry.init({
    dsn: "https://cbaeb97f10f54878889a960ce68ce74f@sentry.io/1726578",
    environment: process.env.REACT_APP_SENTRY_ENV
  });
}

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};

const ScrollToTopWithRouter = withRouter(ScrollToTop);


ReactDOM.render(
  <Provider store={ store }>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <ScrollToTopWithRouter>
          <Route render={({ location }) => <App location={ location }/>} />
        </ScrollToTopWithRouter>
      </Router>
    </PersistGate>
  </Provider>
  , rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
